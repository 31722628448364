import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Ensure Tailwind CSS is configured here
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AlertProvider } from './contexts/AlertContext'; // Adjust the path if necessary

// Ensure the root container is selected correctly
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <AlertProvider>
      <App />
    </AlertProvider>
  </React.StrictMode>
);

// Optional: report web vitals for performance analysis
reportWebVitals();
