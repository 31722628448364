import React from 'react';
import useAllDevotions from '../hooks/useAllDevotions';
import dailyDevotionImage from '../assets/DAILYDEVOTIONS.gif';
import html2canvas from 'html2canvas';
import { useAlert } from '../contexts/AlertContext';
import dayjs from 'dayjs';

const Devotion = () => {
  const currentDate = dayjs().format('YYYY-MM-DD');
  const { devotions, loading, error } = useAllDevotions(currentDate);
  const showAlert = useAlert();

  const handleDownload = (id, date) => {
    const element = document.getElementById(`devotion-post-${id}`);
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.download = `momentumpeak-devotion-${date}.png`;
      link.href = canvas.toDataURL();
      link.click();
      showAlert('Devotion saved to downloads.', 'success');
    });
  };

  // Sort devotions by date in descending order
  const sortedDevotions = [...devotions].sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));

  return (
    <div className="p-4 bg-foregroundLight dark:bg-foregroundDark text-textPrimaryLight dark:text-textPrimaryDark">
      <h2 className="text-3xl md:text-4xl text-center mb-8">Daily Devotions</h2>

      {loading && <div className="flex justify-center"><div className="loader"></div></div>}
      {error && <p className="text-red-500 text-center">{error}</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {sortedDevotions.map((devotion) => (
          <div
            className="bg-backgroundLight dark:bg-backgroundDark text-textPrimaryLight dark:text-textPrimaryDark rounded-lg shadow-lg overflow-hidden relative"
            id={`devotion-post-${devotion.id}`}
            key={devotion.id}
            style={{
              boxShadow: '0 4px 6px rgba(0, 255, 0, 0.5)', // Light mode shadow
              dark: {
                boxShadow: '0 4px 6px rgba(0, 255, 0, 0.8)', // Dark mode shadow
              }
            }}
          >
            <div
              className="h-64 bg-cover bg-center"
              style={{ backgroundImage: `url(${dailyDevotionImage})` }}
            />
            <div className="p-4">
              <div className="flex justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleDownload(devotion.id, devotion.date)}
                    className="bg-primary text-backgroundLight dark:text-backgroundDark hover:bg-primaryHover hover:text-backgroundLight dark:hover:text-backgroundDark transition-colors p-2 rounded-full"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12h2.586a1 1 0 010 1.414l-7.293 7.293a1 1 0 01-1.414 0L4.414 13.414A1 1 0 015.828 12H8V4a1 1 0 011-1h6a1 1 0 011 1v8z" />
                    </svg>
                  </button>
                  <span className="text-sm text-textSecondaryLight dark:text-textSecondaryDark">Share</span>
                </div>
                <div className="text-center">
                  <h4 className="text-lg font-bold">{`Devotion Day ${dayjs(devotion.date).dayOfYear()}`}</h4>
                  <p className="text-textSecondaryLight dark:text-textSecondaryDark">{dayjs(devotion.date).format('MMMM D, YYYY')}</p>
                </div>
              </div>
              <div className="mb-4">
                <h4 className="text-lg font-bold mb-2">PRAYER</h4>
                <p className="text-base mb-4">{devotion.prayer || 'No prayer available'}</p>
                <h4 className="text-lg font-bold mb-2">SCRIPTURE</h4>
                <p className="text-base">{Array.isArray(devotion.scriptures) ? devotion.scriptures.join(', ') : devotion.scriptures || 'No scriptures available'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Devotion;
