import React from 'react';
import useDevotion from '../hooks/useDevotion';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(dayOfYear);

const CurrentDevotionWidget = () => {
  const currentDate = dayjs().format('YYYY-MM-DD');
  const { devotion, loading: loadingCurrent, error: fetchErrorCurrent } = useDevotion(currentDate);
  const currentDayOfYear = dayjs().dayOfYear();

  return (
    <div className="p-4 md:p-6 bg-foregroundLight dark:bg-foregroundDark text-textPrimaryLight dark:text-textPrimaryDark rounded-xl border-2 border-primary">
      {loadingCurrent && <p className="text-center text-sm md:text-base">Loading...</p>}
      {fetchErrorCurrent && <p className="text-center text-red-500 text-sm md:text-base">{fetchErrorCurrent}</p>}
      {devotion ? (
        <div className="bg-foregroundLight dark:bg-foregroundDark text-textPrimaryLight dark:text-textPrimaryDark rounded-lg p-4 md:p-6">
          <div className="text-center mb-4">
            <h3 className="text-xl md:text-2xl text-primary mb-2">{`Devotion Day ${currentDayOfYear}`}</h3>
            <p className="text-textSecondaryLight dark:text-textSecondaryDark text-sm md:text-base">{currentDate}</p>
          </div>
          <div className="mb-4">
            <h4 className="text-lg md:text-xl font-bold mb-2">PRAYER</h4>
            <p className="text-base md:text-lg">{devotion.prayer}</p>
          </div>
          <div className="h-[2px] bg-gradient-to-r from-primary via-backgroundLight dark:via-backgroundDark to-primary my-4"></div>
          <div>
            <h4 className="text-lg md:text-xl font-bold mb-2">SCRIPTURE</h4>
            <p className="text-base md:text-lg">{devotion.scriptures}</p>
          </div>
        </div>
      ) : (
        !loadingCurrent && !fetchErrorCurrent && (
          <div className="text-center text-textSecondaryLight dark:text-textSecondaryDark text-sm md:text-base">
            <p>No devotion available for the current date.</p>
          </div>
        )
      )}
    </div>
  );
};

export default CurrentDevotionWidget;
