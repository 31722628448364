import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../api/axiosInstance';

const useStocks = () => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [progress, setProgress] = useState(0); // New state for progress
  const perPage = 50; // Updated perPage to 50
  const minAmount = 500000; // Minimum amount filter

  const fetchStocks = useCallback(async (page) => {
    setLoading(true);
    setProgress(0); // Reset progress
    try {
      const response = await axiosInstance.get(`/api/v1/stocks?page=${page}&per_page=${perPage}&min_amount=${minAmount}`);
      const data = response.data.data;

      const rallyList = data.rally_list || [];
      const sellOffList = data.sell_off_list || [];

      const filteredRallyStocks = rallyList.filter(
        (stock) => stock.currentClose > 0.09
      );

      const filteredSellOffStocks = sellOffList.filter(
        (stock) => stock.currentClose > 0.09
      );

      const mergedStocks = [...filteredRallyStocks, ...filteredSellOffStocks];

      setStocks(mergedStocks);
      setTotalPages(response.data.metadata.total); // Set total pages based on the metadata
      setError(null);
    } catch (err) {
      setError(err.message);
      setStocks([]);
    } finally {
      let progressInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return prev + 10; // Adjust the increment as needed
        });
      }, 100); // Adjust the interval as needed
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStocks(page);
  }, [fetchStocks, page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const refreshStocks = () => {
    fetchStocks(page, true); // Trigger a fresh fetch for the current page
  };

  return {
    stocks,
    loading,
    error,
    page,
    totalPages,
    perPage,
    nextPage,
    prevPage,
    fetchStocks,
    refreshStocks,
    progress, // Return progress
  };
};

export default useStocks;
