import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { HomeIcon, ListBulletIcon, BookOpenIcon, HeartIcon, EnvelopeIcon } from '@heroicons/react/24/solid'; // Import Heroicons
import { useAuth } from '../contexts/AuthContext';
import Logo from '../assets/momentumPeak-logo.png';

const Header = () => {
  const { isAuthenticated, login, logout } = useAuth();

  return (
    <header className="bg-backgroundLight dark:bg-backgroundDark shadow-lg">
      <nav className="container mx-auto flex items-center justify-between p-4">
        <RouterLink to="/">
          <img src={Logo} alt="Momentum Peak Logo" className="h-14" />
        </RouterLink>
        <div className="flex items-center space-x-4">
          <RouterLink to="/about" className="flex items-center space-x-1 text-textPrimaryLight dark:text-primary transition-colors">
            <HomeIcon className="h-5 w-5 text-primary" /> {/* Heroicons for Home */}
            <span className="hidden md:inline">Momentum Peak</span>
          </RouterLink>
          <RouterLink to="/watchlist" className="flex items-center space-x-1 text-textPrimaryLight dark:text-primary transition-colors">
            <ListBulletIcon className="h-5 w-5 text-primary" /> {/* Heroicons for List */}
            <span className="hidden md:inline">Watchlist</span>
          </RouterLink>
          <RouterLink to="/daily-devotion" className="flex items-center space-x-1 text-textPrimaryLight dark:text-primary transition-colors">
            <BookOpenIcon className="h-5 w-5 text-primary" /> {/* Heroicons for Book */}
            <span className="hidden md:inline">Daily Devotion</span>
          </RouterLink>
          <RouterLink to="/donate" className="flex items-center space-x-1 text-textPrimaryLight dark:text-primary transition-colors">
            <HeartIcon className="h-5 w-5 text-primary" /> {/* Heroicons for Heart */}
            <span className="hidden md:inline">Donate</span>
          </RouterLink>
          <RouterLink to="/subscribe" className="flex items-center space-x-1 text-textPrimaryLight dark:text-primary transition-colors">
            <EnvelopeIcon className="h-5 w-5 text-primary" /> {/* Heroicons for Envelope */}
            <span className="hidden md:inline">Subscribe</span>
          </RouterLink>
          {!isAuthenticated ? (
            <>
              <RouterLink to="/join" className="text-textPrimaryLight dark:text-primary transition-colors hover:text-primary">
                Join
              </RouterLink>
              <RouterLink to="/login" className="text-textPrimaryLight dark:text-primary transition-colors hover:text-primary" onClick={login}>
                Login
              </RouterLink>
            </>
          ) : (
            <button className="text-textPrimaryLight dark:text-primary transition-colors hover:text-primary" onClick={logout}>
              Logout
            </button>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;