import { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';

const useDevotion = (date) => {
  const [devotion, setDevotion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!date) return;

    const fetchDevotionByDate = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/v1/devotion/date/${date}`);
        console.log('Response data:', response.data); // Debugging: log the response data
        setDevotion(response.data.data); // Access the nested data field
        setError(null);
      } catch (error) {
        console.error('Error fetching devotion:', error);
        setError('Failed to fetch devotion. Please try again later.');
        setDevotion(null);
      } finally {
        setLoading(false);
      }
    };

    fetchDevotionByDate();
  }, [date]);

  return { devotion, loading, error };
};

export default useDevotion;
