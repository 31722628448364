// src/hooks/useSubscribe.js
import { useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { useAlert } from '../contexts/AlertContext';

const useSubscribe = () => {
  const [loading, setLoading] = useState(false);
  const showAlert = useAlert();

  const subscribe = async (name, email) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/v1/stocks/subscribe', { name, email });
      showAlert('Subscription successful!', 'success');
      return response.data;
    } catch (error) {
      console.error('Subscription failed:', error);
      showAlert('Subscription failed. Please try again.', 'error');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { subscribe, loading };
};

export default useSubscribe;
