import React, { useState } from 'react';
import useSubscribe from '../hooks/useSubscribe';

const SubscribeWidget = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { subscribe, loading } = useSubscribe();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await subscribe(name, email);
      setName('');
      setEmail('');
    } catch (error) {
      // Error handling is done in the hook
    }
  };

  return (
    <div className="p-4 bg-widgetLight dark:bg-widgetDark text-widgetTextLight dark:text-widgetTextDark rounded-lg">
      <h2 className="text-lg md:text-xl font-bold text-center mb-4">Get Daily Watchlist</h2>
      <form onSubmit={handleSubmit} className="space-y-2">
        <div>
          <label className="block text-sm font-medium mb-1" htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full p-2 border border-inputBorderLight dark:border-inputBorderDark rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1" htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-2 border border-inputBorderLight dark:border-inputBorderDark rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="mt-2">
          <button
            type="submit"
            className="w-full bg-primary text-black font-bold py-2 px-4 rounded-lg flex justify-center items-center"
            disabled={loading}
          >
            {loading ? (
              <svg className="animate-spin h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
              </svg>
            ) : (
              'Subscribe'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubscribeWidget;
