import React, { useState, useMemo } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline'; // Importing Tailwind's Heroicons
import useStocks from '../hooks/useStocks';
import CurrentDevotionWidget from './CurrentDevotionWidget';
import SubscribeWidget from './SubscribeWidget';
import DonateWidget from './DonateWidget';

const applySort = (stocks, sortConfig) => {
  if (sortConfig.key) {
    return [...stocks].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }
  return stocks;
};

const formatNumber = (number) => {
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(2)}M`;
  }
  return new Intl.NumberFormat('en-US').format(number);
};

const formatPercentage = (number) => {
  return `${Math.round(number)}%`;
};

const StocksData = () => {
  const { stocks, loading, error, page, totalPages, nextPage, prevPage, refreshStocks } = useStocks();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const sortedStocks = useMemo(() => applySort(stocks, sortConfig), [stocks, sortConfig]);

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <div className="min-h-screen bg-backgroundLight dark:bg-backgroundDark text-textPrimaryLight dark:text-textPrimaryDark p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="md:col-span-2">
          <div className="p-4 bg-foregroundLight dark:bg-foregroundDark text-textPrimaryLight dark:text-textPrimaryDark rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h5 className="text-xl font-semibold text-primary">Watchlist</h5>
              <button
                onClick={refreshStocks}
                className="flex items-center justify-center px-4 py-2 bg-primary text-black dark:text-backgroundDark rounded-lg"
              >
                <ArrowPathIcon className="mr-2 h-5 w-5" /> Refresh
              </button>
            </div>
            {loading ? (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
              </div>
            ) : error ? (
              <p className="text-red-500 text-center">Error fetching stocks data: {error}</p>
            ) : (
              <div className="overflow-auto">
                <table className="min-w-full border border-gray-300 dark:border-gray-700 bg-backgroundLight dark:bg-backgroundDark text-textPrimaryLight dark:text-textPrimaryDark">
                  <thead>
                    <tr className="bg-foregroundLight dark:bg-foregroundDark">
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('ticker')}>Ticker</th>
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('currentClose')}>Current Close</th>
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('avgVol')}>Average Volume</th>
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('currentVolume')}>Current Volume</th>
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('atr')}>ATR</th>
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('dtr')}>DTR</th>
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('atr_percentage')}>ATR %</th>
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('avgPrice')}>Average Price</th>
                      <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700" onClick={() => handleSort('trade_total_volume')}>Trade Total Volume</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedStocks.map((stock, index) => (
                      <tr key={index} className="bg-foregroundLight dark:bg-foregroundDark">
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{stock.ticker}</td>
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{stock.currentClose}</td>
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{formatNumber(stock.avgVol)}</td>
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{formatNumber(stock.currentVolume)}</td>
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{stock.atr}</td>
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{stock.dtr}</td>
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{formatPercentage(stock.atr_percentage)}</td>
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{stock.avgPrice}</td>
                        <td className="p-2 border-t border-gray-300 dark:border-gray-700">{stock.trade_total_volume}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={prevPage}
                disabled={page === 1}
                className="px-4 py-2 bg-primary text-black dark:text-backgroundDark rounded-lg"
              >
                Previous
              </button>
              <p className="text-sm">
                Page {page} of {totalPages}
              </p>
              <button
                onClick={nextPage}
                disabled={page === totalPages}
                className="px-4 py-2 bg-primary text-black dark:text-backgroundDark rounded-lg"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="md:col-span-1 space-y-4">
          <CurrentDevotionWidget />
          <SubscribeWidget />
          <DonateWidget />
        </div>
      </div>
    </div>
  );
};

export default StocksData;
