import { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import dayjs from 'dayjs';

const useAllDevotions = (endDate) => {
  const [devotions, setDevotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllDevotions = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/api/v1/devotion');
        console.log('All devotions response data:', response.data);

        // Filter out future devotions
        const filteredDevotions = response.data.data.filter(devotion =>
          dayjs(devotion.date).isBefore(dayjs(endDate).add(1, 'day'))
        );

        setDevotions(filteredDevotions);
        setError(null);
      } catch (err) {
        console.error('Error fetching all devotions:', err);
        setError('Failed to fetch devotions. Please try again later.');
        setDevotions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAllDevotions();
  }, [endDate]);

  return { devotions, loading, error };
};

export default useAllDevotions;
