import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ThemeToggle from './ThemeToggle'; // Ensure the path to ThemeToggle is correct

const Footer = () => {
  return (
    <footer className="bg-foregroundLight dark:bg-backgroundDark text-textPrimaryLight dark:text-textPrimaryDark text-center p-4 mt-auto">
      <p className="text-sm md:text-base mt-2 font-bold text-red-600 dark:text-red-500">
        Disclaimer: The information provided on this platform is for educational purposes only and should not be considered as financial advice.
      </p>
      <div className="flex justify-center space-x-4 my-4">
        <RouterLink
          to="/subscribe"
          className="hover:text-primary transition-colors dark:hover:text-primary"
        >
          Subscribe
        </RouterLink>
        <RouterLink
          to="/donate"
          className="hover:text-primary transition-colors dark:hover:text-primary"
        >
          Donate
        </RouterLink>
      </div>
      <div className="flex justify-center my-4">
        <ThemeToggle />
      </div>
      <p className="text-xs md:text-sm mt-2">
        Momentum Peak 4.5.17 &copy; 2024 Mazisparrow LLC. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
