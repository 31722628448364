import React, { useEffect } from 'react';

const Donate = () => {
  useEffect(() => {
    const scriptId = 'bmc-button-script';

    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js';
      script.setAttribute('data-name', 'bmc-button');
      script.setAttribute('data-slug', 'momentumpeak');
      script.setAttribute('data-color', '#FFDD00');
      script.setAttribute('data-emoji', '');
      script.setAttribute('data-font', 'Poppins');
      script.setAttribute('data-text', 'Buy me a coffee');
      script.setAttribute('data-outline-color', '#000000');
      script.setAttribute('data-font-color', '#000000');
      script.setAttribute('data-coffee-color', '#ffffff');
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className="max-w-md md:max-w-xl mx-auto p-4 md:p-6 bg-foregroundLight dark:bg-backgroundDark text-textPrimaryLight dark:text-textPrimaryDark rounded-lg">
      <div className="text-center">
        <h2 className="text-xl md:text-2xl font-bold mb-4">Support Momentum Peak</h2>
        <div className="h-1 w-12 bg-gradient-to-r from-primary via-foregroundLight to-primary mx-auto mb-4"></div>
      </div>
      <p className="text-base md:text-lg mb-4">
        Momentum Peak is free to use, but maintaining and developing it requires a significant amount of time and effort. If you find Momentum Peak helpful in your trading and investment endeavors, please consider making a donation. Your support is greatly appreciated and helps us continue to improve and offer more features, ensuring that we can provide you with the best possible tools and insights.
      </p>
      <div className="text-center my-6">
        <a href="https://www.buymeacoffee.com/momentumpeak" target="_blank" rel="noopener noreferrer">
          <img src="https://www.buymeacoffee.com/assets/img/custom_images/yellow_img.png" alt="Buy Me A Coffee" className="mx-auto w-48 md:w-56" />
        </a>
      </div>
      <p className="text-base md:text-lg">
        Thank you for your generosity and support. Together, we can keep Momentum Peak thriving and make it an even better resource for traders and investors like you.
      </p>
    </div>
  );
};

export default Donate;
