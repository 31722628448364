import React from 'react';

const WatchlistSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto">
    <foreignObject width="100%" height="100%">
      <table xmlns="http://www.w3.org/1999/xhtml" className="min-w-full border border-gray-300 dark:border-gray-700 bg-backgroundLight dark:bg-backgroundDark text-red-500 dark:text-primary">
        <thead>
          <tr className="bg-foregroundLight dark:bg-foregroundDark">
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">Ticker</th>
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">Current Close</th>
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">Average Volume</th>
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">Current Volume</th>
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">ATR</th>
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">DTR</th>
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">ATR %</th>
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">Average Price</th>
            <th className="p-2 text-left cursor-pointer border-b border-gray-300 dark:border-gray-700">Trade Total Volume</th>
          </tr>
        </thead>
        <tbody>
          {/* Add rows with your data */}
          <tr className="bg-foregroundLight dark:bg-foregroundDark">
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">AAPL</td>
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">$145.09</td>
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">78.34M</td>
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">82.55M</td>
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">2.45</td>
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">3.5</td>
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">1.69%</td>
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">$144.00</td>
            <td className="p-2 border-t border-gray-300 dark:border-gray-700">72.12M</td>
          </tr>
          {/* Additional rows can be added here */}
        </tbody>
      </table>
    </foreignObject>
  </svg>
);

export default WatchlistSVG;
