import React, { useState, useEffect } from "react";
import algorithmImage from "../assets/algorithm-image.png";
import WatchlistSVG from "../assets/WatchlistSVG";
import userInterfaceImage from "../assets/user-interface-image.jpg";
import secureImage from "../assets/secure-image.png";
import image1 from "../assets/1.png";  // Import images directly
import image2 from "../assets/2.png";
import image3 from "../assets/3.png";
import CurrentDevotionWidget from "./CurrentDevotionWidget";
import SubscribeWidget from "./SubscribeWidget";
import DonateWidget from "./DonateWidget";

// Slideshow Component with Auto-Slide
const Slideshow = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(slideInterval); // Cleanup interval on component unmount
  }, [images.length, interval]);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative w-full h-[200px] md:h-[400px]">
      <img
        src={images[currentIndex]}
        alt={`slide-${currentIndex}`}
        className="w-full h-full object-cover bg-foregroundLight dark:bg-foregroundDark rounded-lg shadow-lg"
      />
      <button
        onClick={goToPrevSlide}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 text-primary text-2xl"
      >
        &#10094;
      </button>
      <button
        onClick={goToNextSlide}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 text-primary text-2xl"
      >
        &#10095;
      </button>
    </div>
  );
};

const About = () => {
  const slideImages = [image1, image2, image3];

  return (
    <div className="p-4 bg-backgroundLight dark:bg-backgroundDark text-textPrimaryLight dark:text-textPrimaryDark">
      <h2 className="text-3xl md:text-4xl text-center mb-8">
        Unlock Trading Success with Momentum Peak!
      </h2>

      <div className="flex flex-col lg:flex-row gap-8">
        <div className="flex-grow lg:w-2/3">
          <div className="bg-foregroundLight dark:bg-foregroundDark p-4 md:p-6 rounded-lg shadow-lg mb-8">
            <div className="text-center mb-8">
              <h3 className="text-2xl md:text-3xl mb-4 text-primary">
                Your Ultimate Equity Watchlist Platform
              </h3>
              <p className="text-base md:text-lg text-left text-textSecondaryLight dark:text-textSecondaryDark">
                Elevate your trading strategy with Momentum Peak, the premier
                platform designed to identify equities with significant momentum
                surges across all major American stock exchanges. Our
                cutting-edge algorithms diligently scan the market, compiling a
                daily watchlist of stocks poised for action, ensuring you never
                miss a lucrative opportunity.
              </p>
            </div>

            {/* Slideshow Card */}
            <div className="mb-8">
              <div className="bg-foregroundLight dark:bg-foregroundDark rounded-lg overflow-hidden shadow-lg">
                <Slideshow images={slideImages} interval={5000} /> {/* 5 seconds interval */}
                <div className="p-4">
                  <h3 className="text-xl md:text-2xl text-primary">
                    Real-Time Data Analysis
                  </h3>
                  <p className="text-base md:text-lg text-left text-textSecondaryLight dark:text-textSecondaryDark">
                    Our platform delivers real-time data and insights, allowing
                    you to make informed decisions swiftly. With Momentum Peak,
                    you can track the latest market trends and adjust your
                    strategy accordingly.
                  </p>
                </div>
              </div>
            </div>

            {/* Masonry-style Cards */}
            <div className="columns-1 sm:columns-2 lg:columns-2 gap-6 space-y-6">
              <div className="break-inside bg-foregroundLight dark:bg-foregroundDark rounded-lg overflow-hidden shadow-lg">
                <img
                  src={algorithmImage}
                  alt="Algorithm"
                  className="w-full h-40 md:h-[300px] object-cover"
                />
                <div className="p-4">
                  <h3 className="text-xl md:text-2xl text-primary">
                    Advanced Momentum Algorithms
                  </h3>
                  <p className="text-base md:text-lg text-left text-textSecondaryLight dark:text-textSecondaryDark">
                    Momentum Peak leverages sophisticated algorithms to detect
                    equities exhibiting a significant increase in momentum. Our
                    technology provides you with a curated list of
                    high-potential tradeable stocks, giving you a competitive
                    edge in the fast-paced world of trading.
                  </p>
                </div>
              </div>

              <div className="break-inside bg-foregroundLight dark:bg-foregroundDark rounded-lg overflow-hidden shadow-lg">
                <div className="w-full h-40 md:h-[300px] flex justify-center items-center">
                  <WatchlistSVG />
                </div>
                <div className="p-4">
                  <h3 className="text-xl md:text-2xl text-primary">
                    Comprehensive Watchlist
                  </h3>
                  <p className="text-base md:text-lg text-left text-textSecondaryLight dark:text-textSecondaryDark">
                    Stay ahead of the market with our daily updated watchlist.
                    We cover all major American stock exchanges, including NYSE,
                    NASDAQ, and AMEX, ensuring you have access to a wide range
                    of investment opportunities.
                  </p>
                </div>
              </div>

              <div className="break-inside bg-foregroundLight dark:bg-foregroundDark rounded-lg overflow-hidden shadow-lg">
                <img
                  src={userInterfaceImage}
                  alt="User Interface"
                  className="w-full h-40 md:h-[300px] object-cover"
                />
                <div className="p-4">
                  <h3 className="text-xl md:text-2xl text-primary">
                    User-Friendly Interface
                  </h3>
                  <p className="text-base md:text-lg text-left text-textSecondaryLight dark:text-textSecondaryDark">
                    Designed with traders in mind, Momentum Peak offers an
                    intuitive and easy-to-navigate interface. Whether you're a
                    seasoned trader or just starting, our platform ensures a
                    seamless user experience.
                  </p>
                </div>
              </div>

              <div className="break-inside bg-foregroundLight dark:bg-foregroundDark rounded-lg overflow-hidden shadow-lg">
                <img
                  src={secureImage}
                  alt="Secure"
                  className="w-full h-40 md:h-[300px] object-cover"
                />
                <div className="p-4">
                  <h3 className="text-xl md:text-2xl text-primary">
                    Secure and Reliable
                  </h3>
                  <p className="text-base md:text-lg text-left text-textSecondaryLight dark:text-textSecondaryDark">
                    With secure AWS authentication, Momentum Peak guarantees the
                    highest standards of data protection and reliability. Your
                    personal information remains safe and accessible at all
                    times.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Widgets Section */}
        <div className="w-full lg:w-1/3 flex flex-col gap-8">
          <CurrentDevotionWidget />
          <SubscribeWidget />
          <DonateWidget />
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-4xl text-center mb-8 text-primary">
          Why Choose Momentum Peak?
        </h2>
        <p className="text-lg text-left text-textSecondaryLight dark:text-textSecondaryDark mb-4">
          In the dynamic world of stock trading, having the right tools can make
          all the difference. Momentum Peak is your trusted partner in
          navigating the complexities of the market. Our platform is built to
          empower traders with the information they need to succeed, providing
          you with a daily edge through our meticulously compiled watchlist.
        </p>
        <p className="text-lg text-left text-textSecondaryLight dark:text-textSecondaryDark mb-4">
          Experience the power of advanced algorithms, real-time data, and a
          user-friendly interface with Momentum Peak. Start maximizing your
          trading potential today and stay ahead of the market curve.
        </p>
        <p className="text-lg text-center mt-4">
          Join Momentum Peak Now: Discover the ultimate watchlist platform and
          transform your trading strategy. Visit Momentum Peak to get started
          and elevate your trading game to the next level.
        </p>
        <div className="text-center mt-8">
          <button className="bg-primary text-black dark:text-black px-6 py-3 rounded-lg text-lg">
            Join Momentum Peak Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
