import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Devotion from "./components/Devotion";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import StocksData from "./components/StocksData";
import Donate from "./components/Donate";
import Join from "./components/Join";
import Login from "./components/Login";
import Subscribe from "./components/Subscribe";
import { AuthProvider } from "./contexts/AuthContext";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-foreground dark:bg-background text-textPrimary dark:text-textSecondary">
          <Header />
          <div className="container mx-auto p-4">
            <Routes>
              <Route path="/" element={<About />} />
              <Route path="/about" element={<About />} />
              <Route path="/watchlist" element={<StocksData />} />
              <Route path="/daily-devotion" element={<Devotion />} />
              <Route path="/donate" element={<Donate />} />
              <Route path="/join" element={<Join />} />
              <Route path="/login" element={<Login />} />
              <Route path="/subscribe" element={<Subscribe />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
