import React from 'react';
import { useAuth } from '../contexts/AuthContext'; // Ensure the correct path

const Login = () => {
  const { login } = useAuth();

  return (
    <div className="max-w-lg mx-auto p-4 md:p-6 bg-foregroundLight dark:bg-backgroundDark text-textPrimaryLight dark:text-textPrimaryDark rounded-lg shadow-lg">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-center">Login Page</h1>
      {/* Add your login form here */}
      <button
        onClick={login}
        className="w-full bg-primary text-textPrimaryLight dark:text-backgroundDark font-bold py-2 px-4 rounded-lg"
      >
        Login
      </button>
    </div>
  );
};

export default Login;
